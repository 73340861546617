<template>
  <v-container style="position: absolute;width: 100%;height: 100%;display: table;left: 0; top: 0;padding: 0!important;max-width: 100%;">
    <div style="width: 50px; height: 50px; border-radius: 50px;background-color: goldenrod;position: fixed;left: 10%; top: 10%; opacity: .4;"></div>
    <div style="width: 45px; height: 45px; border-radius: 50px;background-color: goldenrod;position: fixed;right: 20%; top: 8%; opacity: .4;"></div>
    <div style="width: 30px; height: 30px; border-radius: 50px;background-color: goldenrod;position: fixed;right: 12%; top: 15%;"></div>
    <div style="width: 35px; height: 35px; border-radius: 50px;background-color: goldenrod;position: fixed;bottom: 10%; left: 35%;"></div>
    <div style="width: 30px; height: 30px; border-radius: 50px;background-color: goldenrod;position: fixed;bottom: 50%; left: 8%;opacity: .1;"></div>
    <div style="width: 400px; height: 400px;position: fixed; top: 15%; left: 15%;background-color: goldenrod;border-radius: 50px;transform: rotate(-24deg);opacity: .1;"></div>
    <div style="width: 400px; height: 400px;position: fixed; bottom: 15%; right: 15%;background-color: goldenrod;border-radius: 50px;transform: rotate(-60deg);opacity: .1;"></div>
    <div style="display: table-cell; vertical-align: middle;">
      <v-row  color="white" class="my_row login" style=" max-width: 900px;margin: 0 auto;background-color: #fff;border-radius: 30px;overflow: hidden;box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.15);margin: 0 auto!important;">
        <v-col style="margin: 0 auto;padding: 50px;z-index: 2;background-color: #fff;" sm="6" md="6" cols="6">
          <v-card
              color="white"
              class="pa-10 rounded-0 no_border wrapper_form"
          >
            <h1 style="padding:15px 0;" class="mb-6 line_40">{{ $t("welcome_back") }}</h1>
            <h4 style="padding:15px 0;font-size: 12px;font-weight: normal;" >{{ $t('loginstorecontent') }}</h4>
            <div>
              <p style="font-weight: bold;font-size: 16px;margin-bottom: 7px;">{{ $t('username') }}</p>
              <v-text-field
                class="pt-1"
                outlined
                style="border-radius: 300px!important;"
                v-model="username"
                placeholder="Username"
                :rules="[(v) => !!v || $t('is_required')]"
                required
              />
            </div>
            <div >
              <p style="font-weight: bold;font-size: 16px;margin-bottom: 7px;">{{ $t('password') }}</p>
              <v-text-field
                class="pt-1"
                outlined
                v-model="pwd"
                placeholder="Password"
                type="password"
                :rules="[(v) => !!v || $t('is_required')]"
                required
              />
            </div>
            <div style="height: 30px;"></div>
            <div style="
              overflow: hidden;
              background: lightgoldenrodyellow;
              padding: 0 10px;
              border-radius: 15px;
              border: 1px solid goldenrod;
            ">
              <div style="clear: both;float: left;width: 50%;">
                <v-checkbox style="margin-top: 10;padding: 0;height: 40px;" label="Remember Me" v-model="rememberMe"></v-checkbox>
              </div>
              <div style="height: 56px;float: right;text-align: right; width: 50%;position: relative;display: table;">
                <div style="display: table-cell; vertical-align: middle;">
                  <v-btn
                      color="primary"
                      @click="Login"
                      style="background-color: goldenrod;border-radius: 10px!important;width: 90%;"
                      class="text-bold btn_signup  "
                  >{{ $t("sign_in") }}
                  </v-btn>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col style="margin: 0 auto;padding: 0;background-color: grey;z-index: 2;" sm="6" md="6" cols="6">
          <v-carousel
            style="width: 100%; height: 100%;"
            :show-arrows="false"
            cycle
            hide-delimiters
          >
            <v-carousel-item
              v-for="s in slides"
              :key="s.id"
            >
              <v-sheet
                :style="'background: url(' + s.bg + ') no-repeat center center / cover;border-radius: 0 30px 30px 0;'"
                height="100%"
              >
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <div style="width: 100%; position: fixed; top: 20px; height: 60px; text-align: center; left: 0; background: none; padding: 10px 0;">
          <img src="../assets/images/bench.png" style="width: auto; height: 100%;"/>
        </div>
        <div style="width: 100%; position: fixed; bottom: 0px; height: 60px; text-align: center; left: 0; background: rgba(218, 165, 32, .1); padding: 10px 0;">
          <img src="../assets/images/made_in_cambodia01.png" style="width: auto; height: 100%;"/>
        </div>
        <div style="width: 100%; position: fixed; bottom: 70px; height: 60px; text-align: center; left: 0; background: none; padding: 10px 0;">
          <div style="text-align: center;width: 150px;margin: 0 auto;">
            <a class="socialfbfoot" target="_blank" href="https://www.facebook.com/BenchPOS" alt="Facebook Page"></a>
            <a class="socialttfoot" target="_blank" href="https://www.tiktok.com/@benchbybanhji" alt="TikTok"></a>
            <a class="socialwebfoot" target="_blank" href="https://benchpos.com" alt="Website"></a>
          </div>
        </div>
        <LoadingMe
              :isLoading="showLoading"
              style="padding: 0"
              :fullPage="false"
              :myLoading="true" />
      </v-row>
    </div>
  </v-container>
</template>
<script>
// const cookieJS = require("@/cookie.js");
import jwt from "jsonwebtoken";
const botHandler = require("@/scripts/commerce/handler/telegramBotHandler")
const commerceHandler = require("@/scripts/commerce/handler/commerceHandler")
import { dataStore } from "@/observable/store";
import StoreModel from "@/scripts/commerce/model/Store"
export default {
  name: "BanhJiOrderLogin",
  data: () => ({
    year: new Date().getFullYear(),
    username: '',
    pwd: '',
    rememberMe: false,
    showLoading: false,
    slides: []
  }),
  async mounted(){
    let ruser  = localStorage.getItem('banhjicounter-remember-user')
    // window.console.log(ruser, 'ruser')
    if(ruser != null && ruser != ''){
      let user = JSON.parse(ruser)
      this.username = user.username
      this.pwd = user.password
      this.rememberMe = true
    }
    await this.loadSlide()
  },
  components: {
    LoadingMe: () => import(`@/components/Loading`),
  },
  methods: {
    async setCookie(cname, cvalue, exdays, domain) {
			const d = new Date();
			d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
			const expires = "expires=" + d.toUTCString();
			document.cookie =
			cname + "=" + cvalue + ";" + expires + ";domain=" + domain + ";path=/";
		},
    async Login() {
      // const login = cookieJS.login(this.username, this.pwd);
      let d = {
        username: this.username,
        type: 'counter',
        password: this.pwd
      }
      let instituteId = ''
      let cookiedata = {}
      this.showLoading = true
      await commerceHandler.storeLogin(d).then(res=>{
        window.console.log(res, 'res back from login')
        if(res.data.data.length <= 0){
          this.showLoading = false
          alert('Username & Password incorrect')
          return
        }
        if(res.data.result.status){
          let it = res.data.result.item
          if(it.pk.indexOf('comm-regis-') == -1){
            this.showLoading = false
            alert('Username & Password incorrect')
            return
          }
          let counter =  {
            name: it.name,
            sk: it.sk,
            phone: it.phone,
            pk: it.pk,
            id: it.pk
          }
          cookiedata = {
            item: counter,
            token: res.data.result.token,
            instituteId: res.data.result.item.sk,
            creator: '',
            user: '',
            name: '',
            email: '',
            plan: '',
            accessToken: res.data.result.token
          };
          let tokenName= "banhji-counter-token" + process.env.VUE_APP_MODE
          let token = jwt.sign(cookiedata, process.env.VUE_APP_JWT_SESCRET, {
            expiresIn: 86400,
          });
          // window.console.log(token, '->token')
          this.setCookie(
            tokenName,
            token,
            365,
            ''
          );
          if(this.rememberMe){
            let user = {
              username: this.username,
              password: this.pwd
            }
            localStorage.setItem('banhjicounter-remember-user', JSON.stringify(user))
          }
          
          instituteId = res.data.result.item.sk
          dataStore.instituteId = instituteId
          localStorage.setItem('instituteId', instituteId);
          localStorage.setItem(instituteId + 'commRegister', JSON.stringify(res.data.result.item));
          let store = new StoreModel(res.data.result.store)
          store.username = ''
          store.secretCode = ''
          store.oldRegisters = []
          store.registers = []
          localStorage.setItem(instituteId + 'commStore', JSON.stringify(store));
          if(Object.keys(res.data.result.session).length > 0){
            localStorage.setItem(instituteId + 'session', JSON.stringify(res.data.result.session));
          }
          this.loadInst(instituteId)
          // 
        }else{
          this.showLoading = false
          alert('Username & Password incorrect')
        }
      })
      
    },
    async loadInst(instituteId){
      let data = {
            sk: instituteId,
            pk: instituteId,
            db: 'entity'
        }
        await commerceHandler.generalGet(data).then(res=>{
            this.showLoading = false
            let data = res.data.data
            if(data.Items.length > 0){
              // window.console.log(data, 'data')
              let inst = {
                ...data.Items[0],
                id: data.Items[0].pk
              }
              localStorage.setItem('institute', JSON.stringify(inst));
              this.showLoading = false
              this.$snotify.success('Success full')
              setTimeout(()=>{
                window.location.reload()
              }, 500)
            }
        })
      // await intHandler.getInstitute(instituteId).then(res=>{
      //   // window.console.log(res, 'res from institute')
      //   localStorage.setItem('institute', JSON.stringify(res.data.data));
      //   this.showLoading = false
      //   this.$snotify.success('Success full')
      //   setTimeout(()=>{
      //     window.location.reload()
      //   }, 500)
      // })
    },
    async loadSlide(){
      this.slides = []
      let d = {
        type: 'benchslide'
      }
      botHandler.nomBot(d).then((r)=>{
        let data = r.data.data
        let slides = []
        for(let i = 0; i < data.length; i++){
          if(i > 0){
            if(data[i][3] == 'active'){
              slides.push({
                bg: data[i][2]
              })
            }
          }
        }
        this.slides = slides
      })
    }
  },
};
</script>

<style scoped>
.link_app {
  display: block;
  padding: 10px;
}

.v-btn--outlined {
  border: thin solid #d6d6d6 !important;
}

.link_app:hover {
  background: rgb(226, 226, 226);
}

.stepwizard-step p {
  margin-top: 10px;
}

.stepwizard-row {
  display: table-row;
}

.stepwizard {
  display: table;
  width: 100%;
  position: relative;
}

.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 2px 0;
  font-size: 20px;
  line-height: 1.428571429;
  border-radius: 15px;
  font-weight: 700;
  background-color: #000;
  color: #fff;
}

.btn-circle p {
  color: #000;
  font-size: 25px;
  font-weight: 700;
  float: left;
  text-align: left;
  position: absolute;
  top: -3px;
  right: 0;
  margin-top: 0;
}

.btn-primary p {
  color: #007bff;
}

.btn-circle.btn-primary {
  background-color: #007bff !important;
}

.img_center {
  height: 70px;
  display: block;
  margin: auto;
}

.my_row {
  margin-top: 11% !important;
}

@media (max-width: 768px) {
  .screen_small {
    margin-top: 0px;
  }

  .screen_small3 {
    margin-top: 60px;
  }

  .my_row {
    margin-top: 4% !important;
  }
}

.footer {
  color: #333;
  font-size: 16px;
  height: auto;
  float: left;
  width: 80%;
  position: fixed;
  bottom: 0;
  padding: 15px 0;
}

.text-truly {
  width: 80%;
  line-height: 40px;
  font-size: 36px;
}

@media (max-width: 768px) {
  .footer {
    color: #333;
    font-size: 16px;
    height: auto;
    float: left;
    width: 100%;
    position: relative;
    bottom: 0;
    padding: 15px 0;
  }

  .text_pwd {
    line-height: 11px !important;
  }
}

@media (max-width: 992px) {
  .screen_small {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .text-that-also {
    font-size: 18px;
    width: 100% !important;
    line-height: 25px;
  }

  .text_pwd {
    line-height: 11px !important;
  }

  .screen_small2 {
    margin-top: 200px !important;
  }

  .text-truly {
    width: 100%;
  }
}

@media (max-width: 1030px) {
  .text-that-also {
    font-size: 18px;
    width: 100% !important;
    line-height: 25px;
  }

  .text-truly {
    width: 100%;
  }

  .text_pwd {
    line-height: 11px !important;
  }
}

.line_13 {
  line-height: 13px;
}

.line_40 {
  line-height: 40px;
}

.to_access {
  font-size: 40px;
  line-height: 49px;
  /* font-family: "Niradei-Light", serif !important; */
}

.that_also {
  font-size: 21px;
  color: #fff;
  line-height: 26px;
  /* font-family: 'Niradei-Light'; */
}
</style>
